<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-shop"
        :class="{'show': mqShallShowLeftSidebar}"
      >

        <!-- Filters' Card -->
        <b-card>
        <div class="item-options text-center">
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            @click="clicked()"
          >
            <feather-icon icon="DownloadIcon" class="mr-50" />
            <span>مراجعة الطلبات السابقة</span>
          </b-button>
        </div>
          <div class="product-categories">
            <h6 class="filter-title">
               {{$t("Departments")}}
            </h6>
            <b-form-radio-group
              v-model="department_id"
              class="categories-radio-group"
              stacked
              value-field="id"
              text-field="title"
              :options="departments"
            />
          </div>
          <div class="product-categories">
            <h6 class="filter-title">
               {{$t("Categories")}}
            </h6>
            <b-form-radio-group
              v-model="category_id"
              class="categories-radio-group"
              stacked
              value-field="id"
              text-field="title"
              :options="categories"
            />
          </div>

          <!-- Brands -->
          <div v-show="subs.length>0" class="brands">
            <h6 class="filter-title">
               {{$t("Sub Category")}}
            </h6>
            <b-form-radio-group
              v-model="sub_id"
              class="brands-radio-group"
              stacked
              :options="subs"
              value-field="id"
              text-field="title"
            />
          </div>
          
        </b-card>
      </div>
    </div>
  <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import {
  BButton,
  BRow, BCol, BCard, BFormRadioGroup, BLink,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import useAppCustomizer from "@core/layouts/components/app-customizer/useAppCustomizer";
export default {
  components: {
    BRow,
    BCol,
    BFormRadioGroup,
    BLink,
    BCard,
  BButton,

    // 3rd Party
    VueSlider,
  },
  data() {
    return{
    category_id:null,
    sub_id:null,
    categories: [],
    department_id:null,
    departments: [],
    subs: []
    }
  },
  watch:{
    category_id: function (val) {
      this.updateCategory(val)
      this.getSubs()
    },
    sub_id: function (val) {
      this.updateSub(val)
    },
    department_id: function (val) {
      this.updateDepartment(val)
    },
    "$i18n.locale": function (val) {
      this.getDepartments();
      this.getCategories();
    },
  },
  mounted() {
    this.getCategories()
    this.getSubs()
    this.getDepartments()
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const {
    // Layout Type
    layoutType,
      layoutTypeOptions} = useAppCustomizer()
      return {
        // Layout Type
      layoutType,
      layoutTypeOptions,
      }
  },
  methods:{
    async getDepartments(){
      await this.axios
        .get(
          `department/clint?take=100&skip=0&select=true&type=5`,{
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((res) => {
          console.log(res)
          this.departments = [];
          this.departments = res.data.items;
        })
    },
    clicked(){
      useAppCustomizer().layoutType['value']="vertical"
      // store.commit('app-config/UPDATE_CONTENT_WIDTH', 'vertical')
    },
    reClicked(){
      useAppCustomizer().layoutType['value']="horizontal"
      // store.commit('app-config/UPDATE_CONTENT_WIDTH', 'vertical')
    },
    async getCategories(){
      await this.axios
        .get(
          `categories/clint?take=100&skip=0&select=true`,{
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((res) => {
          console.log(res)
          this.categories = [];
          this.categories = res.data.items;
        })
    },
    async getSubs(){
      if (isNaN(this.category_id)) {
        this.category_id = this.category_id["id"];
      }
      await this.axios
        .get(
          `subcategories/clint?take=100&skip=0&category_id=${this.category_id}`,{
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((res) => {
          this.subs = [];
          this.subs = res.data.items;
        })
    },
    updateCategory(x) {
      this.$store.commit("setCategory", x);
    },
    updateSub(x) {
      this.$store.commit("setSub", x);
    },
    updateDepartment(x) {
      this.$store.commit("setDepartment", x);
    },
    reset() {
      this.$store.commit("reset");
      this.category_id=null,
      this.sub_id=null,
      this.department_id=null
    },
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>
